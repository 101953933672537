import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"

const NasiPartnerzy = () => {
  return (
    <Layout
      title={"Nasi Partenrzy"}
      image={
        <StaticImage
          src={"../assets/images/partners.jpg"}
          alt={"partnerzy"}
          placeholder={"blurred"}
          objectFit={"cover"}
          style={{ position: "absolute" }}
          className={"site-banner"}
        />
      }
    >
      <div
        className={
          "d-flex flex-column flex-lg-row  align-items-center justify-content-center gap-5 flex-wrap"
        }
      >
        <div
          className={
            "d-flex flex-column justify-items-center align-items-center gap-2 p-5"
          }
          style={{ width: "350px" }}
        >
          <div className={"fw-bold text-danger"}>KREDYTY</div>
          <div className={"fw-bold"}>NAZWA FIRMY</div>
          <div>Roland Jankowski</div>
          <div>
            tel. <a href={"tel:501-288-360"}>501 288 360</a>
          </div>
        </div>
        <div
          className={
            "d-flex flex-column justify-items-center align-items-center gap-2 p-5"
          }
          style={{ width: "350px" }}
        >
          <div className={"fw-bold text-danger"}>FOTOWOLTAIKA</div>
          <div className={"fw-bold"}>PHOTON POWER</div>
          <div>Rafał Wiciun</div>
          <div>
            tel. <a href={"tel:501-288-360"}>501 288 360</a>
          </div>
        </div>
        <div
          className={
            "d-flex flex-column justify-items-center align-items-center gap-2 p-5"
          }
          style={{ width: "350px" }}
        >
          <div className={"fw-bold text-danger"}>POŚREDNIK NIERUCHOMOŚCI</div>
          <div className={"fw-bold"}>MIESZKAJ NA SWOIM</div>
          <div>Przemek Komorniczak</div>
          <div>
            tel. <a href={"tel:504-533-077"}>504 533 077</a>
          </div>
        </div>
        <div
          className={
            "d-flex flex-column justify-items-center align-items-center gap-2 p-5"
          }
          style={{ width: "350px" }}
        >
          <div className={"fw-bold text-danger"}>GEODETA</div>
          <div className={"fw-bold"}>USŁUGI GEODEZYJNE GEOS</div>
          <div>Sławek Sawko</div>
          <div>
            tel. <a href={"tel:606-710-935"}>606 710 935</a>
          </div>
        </div>
        <div
          className={
            "d-flex flex-column justify-items-center align-items-center gap-2 p-5"
          }
          style={{ width: "350px" }}
        >
          <div className={"fw-bold text-danger"}>ARCHITEKTURA KRAJOBRAZU</div>
          <div className={"fw-bold"}>seedart</div>
          <div>Julia Wiecka</div>
          <div>
            tel. <a href={"tel:791-045-774"}>791 045 774</a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NasiPartnerzy
